import React, { useMemo } from "react";
import DashBoardTabs from "../dashboard/DashBoardTabs";
import MaerskModelsTable from "../../components/reactTable/MaerskModelsTable"; 
import MaerskModal from "../../components/allManageProjectsModal/maerskModel/MaerskModal";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useState, useEffect, useContext } from "react";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import "./ManageProjects.css";
import axios from "axios";
import { AuthContext } from "../../AuthContext";
import { userRequestMartbuilder } from "../../requestMethod";
import { useValue } from "../../context/ContextProvider";

const ManageProjects = () => {
  const {
    state: { maerskModalTableData, businessData, domainData, productData },
    dispatch,
  } = useValue();

  const [openModal, setOpenModal] = useState(false);
  const [activeTableType, setActiveTableType] = useState(null);

  //token for new axios
  const { getToken, token, error } = useContext(AuthContext);
  //state for table
  const [maerskModalData, setMaerskModalData] = useState();
  const [businessTableData, setBusinessTableData] = useState();
  const [domainTableData, setDomainTableData] = useState();
  const [productTableData, setProductTableData] = useState();

  //axios fetching logic for all 4 table
  const fetchMaerskModalData = async () => {
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.get(`/orgmodel`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        CancelToken: ourRequest.token,
      });
      setMaerskModalData(response?.data);
      dispatch({ type: "MAERSK_MODAL_DATA", payload: response.data });
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    return () => {
      ourRequest.cancel();
    };
  };

  //for business table
  const fetchBusinessTableData = async () => {
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.get(`/business`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        CancelToken: ourRequest.token,
      });
      setBusinessTableData(response?.data);
      dispatch({ type: "BUSINESS_DATA", payload: response.data });
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    return () => {
      ourRequest.cancel();
    };
  };

  //for domain
  const fetchDomainTableData = async () => {
    const ourRequest = axios.CancelToken.source();
    try {
      // let response = await userRequestMartbuilder.get(`/domains`, { //21-05-24
      let response = await userRequestMartbuilder.get(
        `/platform/getAllPlatforms`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );
      setDomainTableData(response?.data);
      dispatch({ type: "DOMAIN_DATA", payload: response.data });
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    return () => {
      ourRequest.cancel();
    };
  };

  //for product
  const fetchProductTableData = async () => {
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.get(`/product`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        CancelToken: ourRequest.token,
      });
      setProductTableData(response?.data);
      dispatch({ type: "PRODUCT_DATA", payload: response.data });
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    return () => {
      ourRequest.cancel();
    };
  };

  useEffect(() => {
    (async () => await getToken())();
    fetchMaerskModalData();
    fetchBusinessTableData();
    fetchDomainTableData();
    fetchProductTableData();
  }, [openModal, token]);

  // add new row
  const handleCreateNewRow = (tableType) => {
    setActiveTableType(tableType);
    setOpenModal(!openModal);
  };

  const columns = useMemo(() => [
    {
      accessorKey: "fqn",
      header: "Fqn",
      size: 140,
    },
    {
      accessorKey: "name",
      header: "Name",
      size: 140,
    },
  ]);

  const Productscolumns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
        size: 140,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          // ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorFn: (row) => row.orgModel.map((model) => model.name).join(", "),
        header: "Business Unit",
        size: 140,
      },
      {
        accessorFn: (row) =>
          row.business.map((business) => business.name).join(", "),
        header: "Domain",
        size: 80,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          // ...getCommonEditTextFieldProps(cell),
          // type: "number",
        }),
      },
      {
        accessorFn: (row) =>
          row.platform.map((platform) => platform.name).join(", "),
        header: "Sub Domain",
        size: 80,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          // ...getCommonEditTextFieldProps(cell),
          // type: "number",
        }),
      },
    ],
    []
  );

  return (
    <div>
      {/* <DashBoardTabs /> */}
      <div className="wrapper" style={{paddingTop:"50px"}}>
        <div className="table_wrapper" style={{ width: "650px" }}>
          <div
            style={{
              margin: "25px 0px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                marginBottom: "20px",
                display: "flex",
                justifyContent: "space-between",
                alignItems:"center",
              }}
            >
              <h2 id="maerskModels">Manage Business Unit</h2>
              <AddCircleOutlineIcon
                style={{
                  fontSize: 32,
                  cursor: "pointer",
                }}
                onClick={() => handleCreateNewRow("MaerskModels")}
              />
            </div>
            <div style={{}}>
              <MaerskModelsTable
                data={maerskModalData}
                tableType="MaerskModels"
                columns={columns}
                fetchMaerskModalData={fetchMaerskModalData}
              />
            </div>
          </div>

          {/* table two */}
          <div
            style={{
              margin: "25px 0px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              // alignItems: "center",
            }}
          >
            <div
              style={{
                marginBottom: "20px",
                display: "flex",
                justifyContent: "space-between",
                alignItems:"center",
              }}
            >
              <h2 id="maerskModels">Manage Domain</h2>
              <AddCircleOutlineIcon
                style={{
                  fontSize: 32,
                  cursor: "pointer",
                }}
                onClick={() => handleCreateNewRow("Business")}
              />
            </div>
            <div style={{}}>
              <MaerskModelsTable
                data={businessTableData}
                tableType="Business"
                columns={columns}
                fetchBusinessTableData={fetchBusinessTableData}
              />
            </div>
          </div>
          {/* table three */}
          <div
            style={{
              margin: "25px 0px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              // alignItems: "center",
            }}
          >
            <div
              style={{
                marginBottom: "20px",
                display: "flex",
                justifyContent: "space-between",
                alignItems:"center",
              }}
            >
              <h2 id="maerskModels">Manage Sub Domain</h2>
              <AddCircleOutlineIcon
                style={{
                  fontSize: 32,
                  cursor: "pointer",
                }}
                onClick={() => handleCreateNewRow("Domain")}
              />
            </div>
            <div style={{}}>
              <MaerskModelsTable
                data={domainTableData}
                tableType="Domain"
                columns={columns}
                fetchDomainTableData={fetchDomainTableData}
              />
            </div>
          </div>
          {/* Fourth table */}
          <div
            style={{
              margin: "25px 0px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              // alignItems: "center",
            }}
          >
            <div
              style={{
                marginBottom: "20px",
                display: "flex",
                justifyContent: "space-between",
                alignItems:"center",
                width: "1000px",
              }}
            >
              <h2 id="maerskModels">Manage Products</h2>

              <Link to="/addProduct">
                <AddCircleOutlineIcon
                  style={{
                    fontSize: 32,
                    cursor: "pointer",
                    color: "black",
                  }}
                  onClick={() => handleCreateNewRow("Products")}
                />
              </Link>
            </div>
            <div style={{ width: "1000px" }}>
              <MaerskModelsTable
                data={productTableData}
                tableType="Products"
                columns={Productscolumns}
                fetchProductTableData={fetchProductTableData}
              />
            </div>
          </div>
        </div>
        <div className="quick_Link_Wrapper">
          <ul>
            <li>
              <a href="#maerskModels">Manage Business Unit</a>
            </li>
            <li>
              <a href="#manageBusiness">Manage Domain</a>
            </li>
            <li>
              <a href="#manageDomains">Manage Sub Domain</a>
            </li>
            <li>
              <a href="#manageProducts">Manage Products</a>
            </li>
          </ul>
        </div>
      </div>
      {openModal && (
        <Box sx={{ position: "absolute", top: "100%", left: 0 }}>
          <MaerskModal
            boolean={openModal}
            onOpen={() => setOpenModal(false)}
            tableType={activeTableType}
            fetchDomainTableData={fetchDomainTableData}
          />
        </Box>
      )}
    </div>
  );
};

export default ManageProjects;

import { useState, useEffect, useContext, useMemo } from "react";
import {
  Button,
  InputAdornment,
  ListSubheader,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/material";
import {
  useUpdateProductsDataMutation,
  useFetchBusinessDataQuery,
  useFetchDomainsDataQuery,
  useFetchModelDataQuery,
} from "../../store";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, Grid, DialogContent, DialogActions } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import MenuItem from "@mui/material/MenuItem";
import Select from "react-select";
import { toast } from "react-toastify";
import WarningPopUp from "../Atoms/WarningPopUp";
import axios from "axios";
import { userRequestMartbuilder } from "../../requestMethod";
import { AuthContext } from "../../AuthContext";
import { useValue } from "../../context/ContextProvider";

const ProductTableEdit = ({
  boolean,
  onToggle,
  row,
  tableType,
  fetchProductTableData,
}) => {
  const { getToken, token, error } = useContext(AuthContext);

  const {
    state: { maerskModalTableData, businessData, domainData },
    dispatch,
  } = useValue();

  const [openModal, setOpenModal] = useState(false);
  const [maerskModel, setMaerskModel] = useState([]);
  const [business, setBusiness] = useState([]);
  const [platform, setPlatform] = useState([]);

  const businessOption = useFetchBusinessDataQuery();
  const domainsOption = useFetchDomainsDataQuery();
  const orgModelOption = useFetchModelDataQuery();
  const [values, setValues] = useState({
    id: row?.original?.id,
    name: row?.original?.name || "",
  });

  const UpdateProductsData = async (updatedData) => {
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.put(
        `/product/${updatedData.id}`,
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );
      fetchProductTableData();
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    if (row) {
      setMaerskModel(
        row?.original?.orgModel?.map((model) => ({
          value: model.id,
          label: model.name,
        })) || []
      );
      setBusiness(
        row?.original?.business?.map((business) => ({
          value: business.id,
          label: business.name,
        })) || []
      );
      setPlatform(
        row?.original?.platform?.map((platform) => ({
          value: platform.id,
          label: platform.name,
        })) || []
      );
      setValues({
        id: row?.original?.id,
        name: row?.original?.name || "",
      });
    }
  }, [row]);

  const notify = () => toast("Name cannot be empty");

  const handleMaerskModelChange = (selectedOptions) => {
    setMaerskModel(selectedOptions);
  };

  const handleBusinessChange = (selectedOptions) => {
    setBusiness(selectedOptions);
  };

  const handlePlatformChange = (selectedOptions) => {
    setPlatform(selectedOptions);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedValues = { ...values, [name]: value };
    setValues(updatedValues);
  };

  const handleModalToggle = () => {
    setOpenModal(!openModal);
  };

  const handleSubmit = (row, tableType) => {
    if (!values.name.trim()) {
      notify();
      return;
    } else {
      UpdateProductsData({
        id: values.id,
        name: values.name,
        business: business.map((item) => ({ id: item.value })),
        domain: platform.map((item) => ({ id: item.value })),
        orgModel: maerskModel.map((item) => ({ id: item.value })),
      });
      onToggle(row, tableType);
    }
  };

  const maerskModelOptions = maerskModalTableData?.map((model) => ({
    value: model.id,
    label: model.name,
  }));

  const businessOptions = businessData?.map((business) => ({
    value: business.id,
    label: business.name,
  }));

  const domainOptions = domainData?.map((domain) => ({
    value: domain.id,
    label: domain.name,
  }));

  return (
    <div>
      <Dialog open={boolean} onClose={() => onToggle(row, tableType)}>
        <DialogContent style={{ width: "600px" }}>
          <Grid container mb={3}>
            <Grid item xs={11}>
              <Typography variant="h6" component="h2">
                Edit Manage Product Data
              </Typography>
            </Grid>
            <Grid color="text.secondary" item xs={1}>
              <CloseIcon
                onClick={() => onToggle(row, tableType)}
                sx={{
                  cursor: "pointer",
                }}
              />
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              variant="subtitle1"
              style={{ flex: 1, fontWeight: 600 }}
            >
              Name
            </Typography>
            <TextField
              margin="dense"
              label="Enter name"
              name="name"
              value={values.name}
              onChange={handleChange}
              style={{ width: "41.5%" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "20px 0px",
            }}
          >
            <Typography
              style={{
                flex: 1,
                color: "black !important",
                fontWeight: 600,
              }}
            >
              Business Unit
            </Typography>
            <Select
              id="maersk-model"
              placeholder="Select Business Unit..."
              aria-label="maersk-model"
              options={maerskModelOptions}
              isMulti
              className="basic-multi-select"
              classNamePrefix="select"
              value={maerskModel}
              onChange={handleMaerskModelChange}
              styles={{
                container: (provided) => ({ ...provided, width: "41.5%" }),
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "20px 0px",
            }}
          >
            <Typography
              style={{
                flex: 1,
                color: "black !important",
                fontWeight: 600,
              }}
            >
              Domains
            </Typography>
            <Select
              id="business"
              placeholder="Select Domain..."
              aria-label="business"
              options={businessOptions}
              isMulti
              className="basic-multi-select"
              classNamePrefix="select"
              value={business}
              onChange={handleBusinessChange}
              styles={{
                container: (provided) => ({ ...provided, width: "41.5%" }),
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "20px 0px",
            }}
          >
            <Typography
              style={{
                flex: 1,
                color: "black !important",
                fontWeight: 600,
              }}
            >
              Sub Domains
            </Typography>
            <Select
              id="domain"
              placeholder="Select Sub Domains..."
              aria-label="platform"
              options={domainOptions}
              isMulti
              className="basic-multi-select"
              classNamePrefix="select"
              value={platform}
              onChange={handlePlatformChange}
              styles={{
                container: (provided) => ({ ...provided, width: "41.5%" }),
              }}
            />
          </div>
          {openModal && <WarningPopUp />}
        </DialogContent>
        <Box sx={{ pb: 2, px: 2 }}>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => handleSubmit(row, tableType)}
            >
              Update
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
};

export default ProductTableEdit;

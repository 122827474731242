import { AddCircleOutline, Refresh } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import PieBlock from "../../components/pieChart/PieBlock";
import JobTable from "../../components/reactTable/JobTable";
import { Link } from "react-router-dom";
import DashBoardTabs from "../dashboard/DashBoardTabs";
import CircularLoader from "../../components/Atoms/CircularLoader";
import axios from "axios";
import { userRequestMartbuilder } from "../../requestMethod";
import { useValue } from "../../context/ContextProvider";
import CLEAR_JOB_DETAILS from "../../context/Reducer";
import JOB_TYPE_MODAL from "../../context/Reducer";
import JobTypeModal from "../../components/selectJobtypeModal/JobTypeModal";
import jobDetails from "../../context/ContextProvider";
import { useDispatch } from "react-redux";
import BatchIngestionTable from "../../components/reactTable/BatchIngestionTable";
import StreamingSyncTable from "../../components/reactTable/StreamingSyncTable";
import DataCard from "../../components/dataCards/DataCards";
import JobSummaryTable from "../../components/reactTable/JobSummaryTable";
import { useMsal } from "@azure/msal-react/dist";
import { AuthContext } from "../../AuthContext";
import { protectedResources } from "../../msalConfig";

const ManageJobs = () => {
  const [jobs, setJobs] = useState({});
  const [stat, setStat] = useState();
  const [jobStatsLT, setJobStatsLT] = useState();
  const [jobStatusStats, setJobStatusStats] = useState();
  const [jobNamesArray, setJobNamesArray] = useState([]);

  // showhide
  const [openModal, setOpenModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState("JobSummaryTable");
  //below useState for jobSummary
  const [CardsData, setCardsData] = useState();
  const [chartDataJobType, setChartDataJobType] = useState();
  const [chartDataProduct, setChartDataProduct] = useState();
  const [chartDataDomain, setChartDataDomain] = useState();
  const [chartDataMaerskModel, setChartDataMaerskModel] = useState();
  const [chartDataFrequeny, setChartDataFrequeny] = useState();
  const [jobSummary, setJobSummary] = useState();
  const {
    state: { jobTypeModal, jobDetails, dataBookToken, sourceConnectionName },
    dispatch,
  } = useValue();

  const { getToken, token, error, getDatabookToken, DCtoken } = useContext(AuthContext);
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const request = {
    scopes: protectedResources.datacraft.scopes,
    account: account,
  };

  const JobTableToggleStyle = {
    fontSize: "16px",
    cursor: "pointer",
    textUnderlineOffset: "10px",
    borderTop: "1px solid black",
    borderLeft: "1px solid black",
    borderRight: "1px solid black",
    padding: "8px",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
  };

  //for toggling the table view
  const JobSummaryTab = {
    marginLeft: "15px",
    color: selectedOption === "StreamingSyncTable" ? "black" : "grey",
    backgroundColor:
      selectedOption === "StreamingSyncTable" ? "white" : "#f0f0f0",
    borderBottom:
      selectedOption === "StreamingSyncTable" ? "none" : "0.5px solid black",
  };

  const StreamingSyncTab = {
    marginLeft: "85px",
    backgroundColor:
      selectedOption === "JobSummaryTable" ? "white" : "#f0f0f0",
    color: selectedOption === "JobSummaryTable" ? "black" : "grey",
    borderBottom:
      selectedOption === "JobSummaryTable" ? "none" : "0.5px solid black",
    // textDecoration:
    // selectedOption === "MartBuilderTable" ? "underline" : "none",
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  useEffect(() => {
    if (DCtoken === undefined || DCtoken === null) {
      getDatabookToken();
    }
    (async () => await getToken())();
    fetchDataSource();
    fetchJobSummary();
    dispatch({ type: "SET_SOURCE_SELECT_BATCH_I", payload:""});
  }, [token, DCtoken]);

  // FETCHING DATABOOK TOKEN
  const fetchDataSource = async () => {
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.get(
        `/ingestion/accessToken`,
        {
          headers: {
            // Define your custom headers here
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );
      // console.log(response.data, "token");
      dispatch({ type: "SET_DATABOOK_TOKEN", payload: response.data });
      // setIsLoading(false);
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
  };

  const fetchJobSummary = async () => {
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.get(`/jobs/summary`, {
        headers: {
          // Define your custom headers here
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        CancelToken: ourRequest.token,
      });
      if (response?.data) {
        // console.log(response?.data)
        setJobSummary(response?.data?.jobs);
        setCardsData(response?.data?.cards);
        setChartDataJobType(
          transformJobTypeData(response?.data?.charts?.JobType)
        );
        setChartDataProduct(
          transformJobTypeData(response?.data?.charts?.Product)
        );
        setChartDataDomain(
          transformJobTypeData(response?.data?.charts?.Domain)
        );
        setChartDataMaerskModel(
          transformJobTypeData(response?.data?.charts?.ByMaerskModel)
        );
        setChartDataFrequeny(
          transformJobTypeData(response?.data?.charts?.JobFrequencyType)
        );
        // console.log(response?.data);
      }
      dispatch({ type: "END_CIRCULAR_LOADING" });
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
  };

  // Fetching Jobs
  // useEffect(() => {
  //   const ourRequest = axios.CancelToken.source();
  //   dispatch({ type: "START_CIRCULAR_LOADING" });
  //   const fetchJobs = async () => {
  //     try {
  //       let response = await userRequestMartbuilder.get(`/jobs`, {
  //         CancelToken: ourRequest.token,
  //       });
  //       setJobs(response.data);

  //       dispatch({ type: "END_CIRCULAR_LOADING" });
  //     } catch (err) {
  //       if (axios.isCancel(err)) {
  //         return;
  //       } else {
  //         console.error(err);
  //       }
  //     }
  //   };
  //   fetchJobs();
  //   return () => {
  //     ourRequest.cancel();
  //   };
  // }, []);

  useEffect(() => {
    if (jobs && jobs.length > 0) {
      const namesArray = jobs.map((row) => row.name);
      setJobNamesArray(namesArray);
    }
  }, [jobs]);
  // console.log(jobNamesArray)

  //for last column
  // useEffect(() => {
  //   const ourRequest = axios.CancelToken.source();
  //   // Dispatch START_CIRCULAR_LOADING if needed
  //   const fetchDatad = async () => {
  //     try {
  //       let response = await userRequestMartbuilder.post(
  //         "https://datamart-api-dev.maestro.maersk.com:443/jobs/dag/runs",
  //         // Send the jobNamesArray as an array in the POST request
  //         JSON.stringify(jobNamesArray), // Convert to JSON string
  //         {
  //           cancelToken: ourRequest.token,
  //           headers: {
  //             "Content-Type": "application/json", // Set content type
  //           },
  //         }
  //       );
  //       setStat(response.data); // Set the retrieved data in the state
  //       // Dispatch END_CIRCULAR_LOADING if needed
  //     } catch (err) {
  //       if (axios.isCancel(err)) {
  //         return;
  //       } else {
  //         console.error(err);
  //       }
  //     }
  //   };
  //   fetchDatad();
  //   return () => {
  //     ourRequest.cancel();
  //   };
  // }, [jobNamesArray, token]);

  // console.log(jobNamesArray);
  // console.log(stat)

  // const fetchJobsByLabel = async (label) => {
  //   // console.log(label);
  //   try {
  //     const response = await userRequestMartbuilder.get(
  //       `/jobs/status/${label}`
  //     );
  //     // console.log(response);
  //     setJobs(response.data);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // const fetchJobsByLoadtype = async (incremental) => {
  //   console.log(incremental);
  //   try {
  //     // Make an API call based on the selected label
  //     // http://20.31.118.198:80/jobs/status/FAILED
  //     const response = await userRequestMartbuilder.get(
  //       `/jobs/loadType/${incremental}`
  //     );
  //     console.log(response);
  //     setJobs(response.data);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // const keyMapping = {
  //   label: "name",
  //   count: "value",
  // };

  // function replaceKeyNames(arrayOfObjects, keyMap) {
  //   // console.log(arrayOfObjects, "arrayOfObjects")
  //   // keyMap should be an object where keys are the old key names and values are the new key names
  //   return arrayOfObjects.map((obj) => {
  //     const newObj = {};
  //     for (const oldKey in obj) {
  //       if (keyMap.hasOwnProperty(oldKey)) {
  //         newObj[keyMap[oldKey]] = obj[oldKey];
  //       } else {
  //         newObj[oldKey] = obj[oldKey];
  //       }
  //     }
  //     // console.log(newObj, "newObj")
  //     return newObj;
  //   });
  // }

  //below code is making the prefilled value of edit as blank

  const handleCreateJobClick = () => {
    // console.log("handleCreateJobClick")
    setOpenModal(!openModal);
  };

  // Load Type Stats
  // useEffect(() => {
  //   const ourRequest = axios.CancelToken.source();
  //   // dispatch({ type: "START_CIRCULAR_LOADING" })
  //   const fetchJobLoadTypeStats = async () => {
  //     try {
  //       let response = await userRequestMartbuilder.get(
  //         `/jobs/statsByJobLoadType`,
  //         {
  //           CancelToken: ourRequest.token,
  //         }
  //       );
  //       setJobStatsLT(replaceKeyNames(response.data, keyMapping));
  //       // console.log(response.data, "stats");
  //       // dispatch({ type: "END_CIRCULAR_LOADING" })
  //     } catch (err) {
  //       if (axios.isCancel(err)) {
  //         return;
  //       } else {
  //         console.error(err);
  //       }
  //     }
  //   };
  //   fetchJobLoadTypeStats();
  //   return () => {
  //     ourRequest.cancel();
  //   };
  // }, []);

  // Job Creation Status Stats
  // useEffect(() => {
  //   const ourRequest = axios.CancelToken.source();
  //   // dispatch({ type: "START_CIRCULAR_LOADING" })
  //   const fetchJobStatusStats = async () => {
  //     try {
  //       let response = await userRequestMartbuilder.get(`/jobs/statsByStatus`, {
  //         CancelToken: ourRequest.token,
  //       });
  //       setJobStatusStats(replaceKeyNames(response.data, keyMapping));
  //       // console.log(response.data, "stats")
  //       // dispatch({ type: "END_CIRCULAR_LOADING" })
  //     } catch (err) {
  //       if (axios.isCancel(err)) {
  //         return;
  //       } else {
  //         console.error(err);
  //       }
  //     }
  //   };
  //   fetchJobStatusStats();
  //   return () => {
  //     ourRequest.cancel();
  //   };
  // }, []);

  function transformJobTypeData(data) {
    return Object.entries(data).map(([name, value]) => ({
      name,
      value,
    }));
  }

  // const transformedData = transformJobTypeData(chartData?.JobType);
  // console.log(transformedData);

  //for fetching the job summary, this have cummilative data including cards

  return (
    <>
      {/* <DashBoardTabs /> */}
      <Box
        component="div"
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: "10px",
          paddingRight: "4.8%",
          marginBlock: "10px",
          paddingTop:"52px",
        }}
      >
        {/* <Link to={"/createJob"}> */} 
        <button
          className="p-1 px-2 rounded-md btn job-btn"
          onClick={handleCreateJobClick}
        >
          <AddCircleOutline fontSize="inherit" />
          <span>Create Job</span>
        </button>
        <button className="p-1 px-2 rounded-md btn job-btn">
          <Refresh fontSize="inherit" />
          <span>Refresh Job</span>
        </button>
        {/* <Button
          variant="outlined"
          size="small"
          sx={{ color: "#247ba0", borderColor: "#247ba0", fontSize: "9.5px" }}
          startIcon={<AddCircleOutline />}
          onClick={handleCreateJobClick}
        >
          Create Job
        </Button> */}
        {/* </Link> */}
        {/* <Button
          variant="outlined"
          size="small"
          sx={{ color: "#247ba0", borderColor: "#247ba0", fontSize: "9.5px" }}
          startIcon={<Refresh />}
        >
          Refresh Job
        </Button> */}
      </Box>
      <Box
        component="div"
        sx={{
          borderRadius: "4px",
        }}
      >
        <div style={{ width: "90%", margin: "0px auto", marginBottom: "7rem" }}>
          <h2
            style={{
              // marginLeft: "75px",
              marginBottom: "30px",
            }}
          >
            Job Health Summary
          </h2>
          {CardsData && (
            <div
              className="dataCardWrapper"
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                  }}
              >
                  {Object.entries(CardsData).map(([key, value]) => {
                      const modifiedKey = key === "Maersk Models" ? "Model" : key;
                      return <DataCard key={key} title={modifiedKey} value={value} />;
                  })}
            </div>
          )}
          {/* <div style={{display:"flex", justifyContent:"space-between", flexWrap:"wrap", margin:"40px 0" }} > */}
          <div
            style={{
              display: "grid",
              gridTemplateRows: "1fr 1fr",
              gridTemplateColumns: "1fr 1fr 1fr",
              rowGap: "3rem",
              margin: "40px 0",
            }}
          >
            {chartDataJobType && (
              <PieBlock data={chartDataJobType} title={"BY JOB TYPE"} />
            )}
            {chartDataProduct && (
              <PieBlock data={chartDataProduct} title={"PRODUCT"} />
            )}
            {chartDataDomain && (
              <PieBlock data={chartDataDomain} title={"DOMAIN"} />
            )}
            {chartDataMaerskModel && (
              <PieBlock data={chartDataMaerskModel} title={"BY MAERSK MODEL"} />
            )}
            {chartDataFrequeny && (
              <PieBlock data={chartDataFrequeny} title={"JOB FREQUENCY TYPE"} />
            )}
          </div>
        </div>
        {/* <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "40px",
            paddingInline: "5%",
            border: "1px solid red",
          }}
        >
          {jobStatsLT && (
            <PieBlock
              data={jobStatsLT}
              title={"JOB LOAD TYPE"}
              onClick={fetchJobsByLoadtype}
            />
          )}
          {jobStatusStats && (
            <PieBlock
              data={jobStatusStats}
              title={"JOB CREATION STATUS"}
              onClick={fetchJobsByLabel}
            />
          )}
        </div> */}
      </Box>
      {openModal && (
        <JobTypeModal onOpen={handleCreateJobClick} boolean={openModal} />
      )}

      <div style={{}}>
        <div style={{ padding: "9px" }}>
          <span
            style={{ ...JobTableToggleStyle, ...StreamingSyncTab }}
            onClick={() => handleOptionClick("JobSummaryTable")}
          >
            Job Summary
          </span>
          <span
            style={{ ...JobTableToggleStyle, ...JobSummaryTab }}
            onClick={() => handleOptionClick("StreamingSyncTable")}
          >
            Streaming Sync
          </span>
        </div>
        {/* <Box sx={{ width: "90%", marginInline: "auto" }}>
          {selectedOption === "JobSummaryTable" && jobs && stat && (
            <JobTable dataT={jobs} dataW={stat} />
          )}
        </Box> */}
        <Box>
          {selectedOption === "StreamingSyncTable" && <StreamingSyncTable/> }
          {selectedOption === "JobSummaryTable" && jobSummary && (
            <JobSummaryTable
              data={jobSummary}
              fetchJobSummary={fetchJobSummary}
            />
          )}
          {/* {jobSummary && (
            <JobSummaryTable
              data={jobSummary}
              fetchJobSummary={fetchJobSummary}
            />
          )} */}
        </Box>
      </div>
    </>
  );
};

export default ManageJobs;

import React from 'react';
import { 
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer 
} from 'recharts';

const ChartExample = ({ data, metricName }) => {
  return (
    <div style={{margin:"50px 0px"}} >
      <div style={{ textAlign: "center" }}>
        <h3>{metricName.charAt(0).toUpperCase() + metricName.slice(1)}</h3>
      </div>
      <ResponsiveContainer width="100%" height={400}>
        <AreaChart
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis 
            dataKey="date" 
            tickFormatter={(tick) => {
              // Format the date for display on the X-axis
              return new Intl.DateTimeFormat('en-US', {
                month: 'short',
                day: 'numeric',
              }).format(tick);
            }}
          />
          <YAxis />
          <Tooltip 
            labelFormatter={(label) => {
              // Format the date in the tooltip
              return new Intl.DateTimeFormat('en-US', {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
              }).format(label);
            }}
          />
          <Area 
            type="monotone" 
            dataKey="value" 
            stroke="rgb(145, 216, 242)" 
            fill="rgb(145, 216, 242)" 
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ChartExample;
